import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import WritingImage from "../components/writingimage"
import SEO from "../components/seo"
import Downloads from "../components/downloads"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p class="red">
      Please check this website for updates often. I may change the guidelines
      as we find better ways to work.
    </p>
    <h1>Setup before class</h1>
    <ul>
      <li>
        Download and install the <a href="https://zoom.us/download" target="_blank">Zoom Client</a> (Desktop, Android or iOS).
        Mobile or tablet is preferred. If you want to use a computer, I suggest attaching a graphics tablet such as <a href="https://www.aliexpress.com/item/4000594600314.html" target="_blank">this</a>.
      </li>
      <li>Sign up for an account</li>
      <li>Add carolyn.aurora@gmail.com as a contact</li>
    </ul>
    <h1>During class</h1>
    <ul>
      <li>Get ready with some paper and stationery.</li>
      <li>
        To show your work and answers, there are a few ways:
        <ul>
          <li>
            If using a phone, write on a paper and then use your phone rear camera to capture the paper.
            <WritingImage />
          </li>
          <li>If using a tablet with pen, share a "whiteboard" and write on the tablet.</li>
        </ul>
      </li>
    </ul>
    <p class="red">For mathematics class, click <Link to="/mathematics">here</Link>.</p>
  </Layout>
)

export default IndexPage
